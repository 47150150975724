@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yantramanav:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, RobotoRegular, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#fafafa !important;
}




/* view point for desk top */
.View_point{ width:50%; margin: 0 auto; padding-top:0px;box-shadow: 0 0 130px #00000017;}
.header_view{width:49.8%; margin: 0 auto; padding-top:20px;}
p{font-weight: 500;}

/* view point for desk top close*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2{font-family: 'Poppins', sans-serif;}

ul li{
  display:block;
}


/*For SplashScreen page*/
.hostaria_bg {
background-image: url('../public/images/pizza.png');
background-size:cover;
position:relative; 
box-shadow:0 810px 284px #00000085 inset;
padding-top:80px;
height: 100vh;
}


 button.menu_btn {
  background-color: #98611100;
  border:2px solid #ffffff;
  border-radius: 52px;
  padding: 12px 70px 12px 70px;
  color:#fff;
}


/*Accordion page*/
.image_side {
  width: 100px !important;
}
.content_side {
  width: calc(100% - 100px) ! important;
  margin-left: 10px;
}

button.accordion-button {
  font-size: 18px;
  font-weight: 600;
  color: #000 !important;
  background-color:#efefef !important;
  font-family: poppins !important;
}
.accordion_body{
  border-radius:8px;
  background:#fff;
}
.accordion-button:focus {
  box-shadow: none ! important;
}
.accordion-button
{
  box-shadow:none !important;
}
.accordion-item{
  border:none !important;
 
}
button.accordion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordion-button:not(.collapsed)::after {
  display:none;
}
.accordion-button::after {
 display:none;
}


/*header country flags*/
.flags_header {
  position: absolute;
  top: 50px;
  right: 0;
  transform: translateX(-50%);
  padding: 7px 12px;
  background-color: #ededed;
  border-radius: 10px;
  box-shadow: 0 0 9px #dddddd inset;
  margin-top: 6px;
  z-index: 999;
}
.flags_header:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  box-shadow:0 6px 10px #0000007d;
  top: 0;
  left: 0;
  border-radius: 10px;
  z-index: -1;
}
ul.flags_header li {
  margin-bottom: 4px;
}

/*Cart page*/

.image_side_01 {
  width:120px !important;
}
.content_side_02 {
  width: calc(100% - 120px) ! important;
}
/*increment/decrement buttons*/
.btn_incre, .btn_incre_number {
  cursor: pointer;
  border-radius: 30px;
  width: 35px;
  height: 35px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_incre{background-color: #eaeeed;}
.btn_incre_number { border: 2px solid #dddd; font-weight: 700; margin: 0 5px;}

.bgred_btn{
  width:100%;
  border-radius: 40px;
  border: 2px solid rgb(190, 22, 34);
  font-weight: 800;
  font-size: 17px ;
  color: #fff;
  background-color: rgb(191 31 42);
  box-shadow: rgb(204 204 204 / 39%) 0px 6px 11px;
     
}

/*carrello page*/
.torna_btn{
  width:100%;
  border-radius: 40px;
  border: 2px solid rgb(190, 22, 34);
  font-weight: 800;
  font-size: 17px;
  color: #be1622;
  background-Color: #0d6efd00;
  box-shadow: rgb(204 204 204 / 39%) 0px 6px 11px;
  
}

.cart-count {
  position: absolute;
  border-radius: 50%;
  background: #000000;
  color: #ffffff;
  right: 4px;
  top: -6px;
  border: none;
  font-weight: bold;
  width: 17px;
  height: 17px;
  text-align: center;
  font-size: 11px;
}

.acc_card {
  background: #fff;
  border-radius:10px !important;
  box-shadow:0px 0px 15px #00000024;
  border:none !important;
  padding:10px;
  margin-bottom:15px;
}


.acc_logo_head, .card_logo_head{
  position: relative !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding-bottom: 20px;
}

.accordion-item [aria-expanded="true"] span:nth-child(2){
  transform: rotate(180%) !important;
}


/* media query for responsive */

@media (max-width:767px) {
  .View_point{ width:100% !important; padding-top:0 !important;}
  p{font-weight: 400;}
  .card_logo_head{display: none !important; padding-bottom: 0 !important;}
  /* .hostaria_bg{height:auto !important;} */
  .header_view{width:100% !important; padding-top:0 !important;}
  .acc_logo_head{padding-top: 20px !important;}
}

.fade.modal.show {
  padding: 10px !important;
}

.sl-bubble1 {
  margin-top: 10px !important;
}

.allergen-loader .sl-bubble1 {
  margin-top: 10px !important;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}



/* itemsDetails */
.main_card{
  background:#efefef;
  margin-top:75px;
  }
  .main_card .head{
    position: relative;
    text-align: center;
    padding: 20px 0;
  }
  .main_card .head span{
    font-size: 30px;
    position: absolute;
    color: #be1622;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .main_card .head h2{
    font-size: 25px;
    font-weight: 600;
    width: 70%;
    margin: 0 auto;
    word-break:break-word;
  }

  .item-details-loader .sl-spinner3{
    height: 25px !important;
  }

  .item-details-loader .sl-spinner3 .sl-loader {
    width: 37px !important;
  }

  @media screen and (max-width:767px) {
    .main_card{
      margin-top:80px
      }
  }